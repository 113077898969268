import { useState } from "react";

const useTooltip = () => {
  const [tooltip, setTooltip] = useState(null);

  const showTooltip = (name) => setTooltip(name);
  const closeTooltip = () => setTooltip(null);

  return [tooltip, showTooltip, closeTooltip];
};

export default useTooltip;
