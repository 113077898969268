import React from "react";

const ASSERTS_FIELDS = {
  filename: "Файл",
  direction: "Направление",
  formname: "Форма",
  progname: "ПО",
  xsd: "Схема для проверки",
  formvers: "Версия формата",
  knd: "КНД",
};

const ERRORS_FIELDS = {
  code: "Код",
  level: "Статус",
  name: "Название",
  description: "Описание",
  path: "Место",
};

const CheckErrorsTable = ({ asserts: { controls, ...rest } }) => {
  const { ok, ...meta } = rest;
  let errors;

  if (!ok && controls?.length) {
    errors = controls.map(({ code, level, name, description, path }) => {
      return { code, level, name, description, path };
    });
  }

  return (
    <div className="errors-description">
      <h2>Протокол форматно-логического контроля</h2>
      <h3>Информация о файле</h3>
      <div className="file-table__wrapper">
        <div className="file-about-table">
          <div className="file-about-table__cell">
            <b>Параметр</b>
          </div>
          <div className="file-about-table__cell">
            <b>Значение</b>
          </div>
        </div>
        <div className="file-about-table">
          {Object.entries(meta).map(([key, value]) => (
            <>
              <div className="file-about-table__cell">
                {ASSERTS_FIELDS?.[key]}
              </div>
              <div className="file-about-table__cell">{value}</div>
            </>
          ))}
        </div>
      </div>
      {errors?.length ? (
        <>
          <h3>Перечень выявленных предупреждений и/или ошибок</h3>
          <div className="file-table__wrapper">
            <div className="file-errors-table">
              <div className="file-errors-table__cell">
                <b>№</b>
              </div>
              {Object.values(ERRORS_FIELDS).map((value) => (
                <div className="file-errors-table__cell">
                  <b>{value}</b>
                </div>
              ))}
            </div>
            <div className="file-errors-table">
              {errors.map((error, index) => (
                <>
                  <div className="file-errors-table__cell">{index + 1}</div>
                  {Object.values(error).map((e) => (
                    <div
                      className={`file-errors-table__cell${
                        !e ? " file-errors-table__cell_empty" : ""
                      }`}
                    >
                      <span>{e || "-"}</span>
                    </div>
                  ))}
                </>
              ))}
            </div>
          </div>
        </>
      ) : null}
    </div>
  );
};

export default CheckErrorsTable;
