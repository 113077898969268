import React, { memo } from "react";

import { ReactComponent as ViewIcon } from "../images/view.svg";
import { ReactComponent as DeleteIcon } from "../images/delete.svg";

const View = memo(() => <ViewIcon />);
const Delete = memo(() => <DeleteIcon />);

const ListItem = ({
  oid,
  name,
  hidden,
  status,
  statusText,
  statusIcon,
  closeTip,
  showTip,
  viewFile,
  deleteFile,
  statusTextStyle,
  direction,
}) => (
  <div
    key={oid}
    className={`files-list__item${hidden ? " files-list__item_hidden" : ""}`}
    onMouseLeave={closeTip}
    onMouseEnter={showTip}
  >
    <span className="files-list__item__name">
      <img
        src={statusIcon}
        alt="processing status"
        className={`files-list__item__name__img${
          status === "null" ? " circle-animation" : ""
        }`}
      />
      <span>{name}</span>
    </span>
    <span className="files-list__item__direction">{direction}</span>
    <span className="files-list__item__status" style={statusTextStyle}>
      {statusText}
    </span>
    <div className="files-list__item__control">
      <button
        type="button"
        className="files-list__control files-list__control_view"
        onClick={viewFile}
      >
        <View />
      </button>
      <button
        type="button"
        className="files-list__control files-list__control_shake files-list__control_delete"
        onClick={deleteFile}
      >
        <Delete />
      </button>
    </div>
  </div>
);

export default memo(ListItem);
