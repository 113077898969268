export const getFilteredFiles = (files, search, checkResults) => {
  let filteredFiles = files.map((file) => {
    return Object.assign(file, {
      ...file,
      hidden: !file.file.name.toLowerCase().includes(search.toLowerCase()),
    });
  });

  if (Object.keys(checkResults).length) {
    filteredFiles = filteredFiles.map((file) => {
      return {
        ...file,
        check: { ...file.check, asserts: checkResults?.[file.meta.oid] },
      };
    });
  }

  return filteredFiles;
};
