import React from "react";

import Tooltip from "./Tooltip";
import ListItem from "./ListItem";

import searchIcon from "../images/search.svg";

import { readFile } from "../utils/fileReader";

import { STATUSES, STATUSES_COLORS, STATUSES_ICONS } from "../constants";

import useTooltip from "../hooks/useTooltip";

const FilesList = ({
  files,
  filteredFiles,
  setFiles,
  filesCount,
  search,
  setSearch,
  openViewer,
}) => {
  const [tooltip, showTooltip, closeTooltip] = useTooltip(files);

  const handleSearch = (e) => setSearch(e.target.value);
  const handleView = (file, assetrs, status) =>
    readFile(file, assetrs, status, openViewer);
  const handleDelete = (fileKey) => {
    const newFiles = files.filter(({ meta: { oid } }) => oid !== fileKey);
    setFiles(newFiles);
  };

  return (
    <>
      <div className={`files-list${!files.length ? " files-list_empty" : ""}`}>
        <div className="files-list__header">
          <span>
            Загруженные файлы <span className="font__gray">{filesCount}</span>
          </span>
          <div className="files-list__search">
            <img src={searchIcon} alt="search icon" />
            <input type="text" value={search} onChange={handleSearch} />
          </div>
        </div>
        <div className="files-list__items">
          <div>
            {filteredFiles.map(
              ({
                file,
                meta: { oid },
                hidden,
                check: { status: responseStatus, direction = null, asserts },
              }) => {
                const status = String(responseStatus);
                const statusText = STATUSES[status];
                const statusIcon = STATUSES_ICONS[status];
                const closeTip = () => closeTooltip(oid);
                const showTip = (e) => {
                  const { top, left } = e.target.getBoundingClientRect();
                  showTooltip({ content: file.name, left, top });
                };
                const viewFile = () => handleView(file, asserts, status);
                const deleteFile = () => handleDelete(oid);
                const statusTextStyle = { color: STATUSES_COLORS[status] };
                return (
                  <ListItem
                    oid={oid}
                    name={file.name}
                    hidden={hidden}
                    status={status}
                    statusText={statusText}
                    statusIcon={statusIcon}
                    closeTip={closeTip}
                    showTip={showTip}
                    viewFile={viewFile}
                    deleteFile={deleteFile}
                    statusTextStyle={statusTextStyle}
                    direction={direction}
                  />
                );
              }
            )}
          </div>
        </div>
      </div>
      {tooltip ? <Tooltip {...tooltip} /> : null}
    </>
  );
};

export default FilesList;
